<template>
  <v-dialog v-model="openModal" persistent max-width="800">
    <v-card>
      <v-card-title class="headline"
        >¿Esta seguro que desea habilitar este registro?</v-card-title
      >
      <v-card-text>Realizada esta acción el registro quedara habilitado.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          :loading="btnModalHabilitar"
          outlined=""
          @click="openModal = false"
        >
          <v-icon left="">mdi-backspace</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" :loading="btnModalHabilitar" @click="habilitar()">
          <v-icon left="">mdi-check-bold</v-icon> Sí, acepto
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      id: 0,
      btnModalHabilitar: false,
    };
  },
  props: {
    objModalHabilitar: {
      type: Object,
    },
  },
  watch: {
    objModalHabilitar(val) {
      (this.openModal = val.openModal), (this.id = val.id);
    },
  },

  methods: {
    habilitar() {
      this.btnModalHabilitar = true;
      this.axios({
        method: "POST",
        url: "api/panel/roles/habilitar",
        data: {
          id: this.id,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.openModal = false;

            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });

            this.$parent.listarRegistros(
              this.$parent.cmbCantidadRegistros,
              this.$parent.paginaActual,
              this.$parent.iptBuscarRegisro
            );
          }
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al habilitar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.btnModalHabilitar = false;
        });
    },
  },
};
</script>
